<template lang="pug">
extends ./pug/InputSelect.pug
</template>

<script>
import InputBase from './InputBase.vue'

export default {
  extends: InputBase,
  name: "InputSelect",
  data(){
    return {
      inputValue : this.multiple ? [] : null,
      options: []
    }
  },
  props: {
    multiple: {
      type: Boolean,
      required: false
    },
    hasNull: {
      type: Boolean,
      required: false
    },
    value: String
  },
  watch: {

  },
  methods:{
    setOptions(options){
      this.options = options
    },
    setSelected(value){
      const select = this.$refs.select
      const options = select.getElementsByTagName('option')

      for(const option of options){
        if(option.value == this.value){
          option.selected = true
        }
      }
    }
  },
  mounted(){
    this.setSelected(this.value)
  }
}
</script>

<style lang="sass">
.form-input-hint
  margin-bottom: 0.2rem
select
  -webkit-appearance: none
  -moz-appearance: none
</style>
