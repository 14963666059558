<template lang="pug">
div
  input-text(label="Name", :form="form", name="name")
  input-select(v-if="isGlobalAdmin", ref="organisationSelect", name="organisationId", label="Organisation", :form="form", v-model="organisationId")
  input-select(v-show="organisationSelected", ref="contactGroupSelect", name="contactGroups", label="Contact Groups", :form="form", multiple)


  input-select(v-show="organisationSelected",ref="templateSelect", v-model="template", label="Select Template")
  input-text-area(v-show="organisationSelected", ref="smsInput", label="SMS Content", :name="smsInputName", v-model="smsContent", :form="form", overlay-highlighter="gsm")
  input-select(v-show="organisationSelected && smsContent", ref="smsCliSelect", v-model="smsCli", label="Select SMS Sender", :name="smsCliInputName", has-null,  :form="form")
  input-text(v-show="organisationSelected", v-if="smsContent && smsCli == '__custom'", ref="smsCustomCliInput", label="Custom SMS Sender" , :name="smsCustomCliInputName", vmodel="smsCustomCli", :form="form")
  input-text-area(v-show="organisationSelected", ref="emailInput", label="Email Content", :name="emailInputName", v-model="emailContent", :form="form")
  input-select(v-show="organisationSelected && emailContent", ref="emailAddressSelect", v-model="emailAddress", label="Select Email Sender", :name="emailAddressInputName", has-null,  :form="form")
  input-text(v-show="organisationSelected && emailContent", v-if="emailAddress == '__custom'", ref="emailFromName", label="Custom Email Sender Name" , :name="emailFromNameName", vmodel="emailFromName", :form="form")
  input-text(v-show="organisationSelected && emailContent", v-if="emailAddress == '__custom'", ref="emailFromAddress", label="Custom Email Sender Address" , :name="emailFromAddressName", vmodel="emailFromAddress", :form="form")


  input-file-upload(v-show="organisationSelected", multiple, label="Email Attachments", :name="emailAttachmentsName", v-if="hasEmailContent",  :form="form")
  input-select(v-show="organisationSelected", ref="callAudioSelect", v-model="callAudio", label="Select Call Audio", :name="callAudioName", has-null,  :form="form")

  input-select(v-show="callAudio && organisationSelected" ref="outboundCallCliSelect", v-model="outboundCallCli", label="Select Outbound Caller", :name="outboundCallCliName", has-null,  :form="form")
</template>

<script>

import InputText from '../inputs/InputText'
import InputSelect from '../inputs/InputSelect'
import InputTextArea from '../inputs/InputTextArea'
import InputFileUpload from '../inputs/InputFileUpload'


export default {
  name: "AlertTemplate",

  components: {
    InputText,
    InputSelect,
    InputTextArea,
    InputFileUpload
  },
  props: {
    isGlobalAdmin: Boolean,
    form: String,
    smsInputName: String,
    emailInputName: String,
    emailAttachmentsName: String,
    smsCustomCliInputName: String,
    smsCliInputName: String,
    callAudioName: String,
    outboundCallCliName: String,
    emailFromNameName: {
      type: String,
      default: 'emailFromName'
    },
    emailFromAddressName: {
      type: String,
      default: 'emailFromAddress'
    },
    emailAddressInputName: {
      type: String,
      default: 'emailAddress'
    }
    // emailReplyToName: {
    //   type: String,
    //   default: 'emailReplyTo'
    // }

  },
  data(){
    return {
      templates: [],
      template: null,
      smsContent: null,
      emailContent: null,
      callAudios: [],
      callAudio: null,
      smsCustomCli: null,
      smsCli: null,
      smsClis: [],
      emailFrom: null,
      outboundCallCli: null,
      outboundCallClis: [],
      organisationId: null,
      contactGroups: [],
      contactGroup: null,
      organisations: [],
      emailAddress: null,
      emailAddresses: [],
      emailFromName: null,
      emailFromAddress: null
      // emailReplyTo: null

    }
  },
  mounted(){
    this.loadOrganisationOptions()

    if(!this.isGlobalAdmin){
      this.loadTemplateOptions()
      this.loadCallAudioOptions()
      this.loadSmsCliOptions()
      this.loadOutboundCallCliOptions()
      this.loadContactGroupOptions()
      this.loadEmailAddressOptions()

    }

  },
  computed:{
    hasEmailContent(){
      return this.emailContent && this.emailContent.trim().length > 0
    },
    organisationSelected(){
      return !this.isGlobalAdmin || this.organisationId
    }
  },
  methods:{
    loadTemplateOptions(){
      this.templates = []
      this.$http.get("/templates", {params: { organisation: this.organisationId }}).then(({data}) => {
        this.templates = data.map((item)=> {
          return {
            value: item.id,
            name: item.name
          }
        })
      })
    },
    loadCallAudioOptions(){
      this.callAudios = []
      this.$http.get("/call-audio", {params: { organisation: this.organisationId }}).then(({data}) => {
        this.callAudios = data.map((item)=> {
          return {
            value: item.id,
            name: item.name
          }
        })
      })
    },
    loadSmsCliOptions(){

      this.smsClis = []
      this.smsClis.push({
        value: '__custom',
        name: 'Set Custom Sender'
      })

      this.$http.get("/phone-numbers?sms", {params: { organisation: this.organisationId }}).then(({data}) => {
        

        data.forEach((item)=> {

          const name = (() => {
            if(item.name){
              return `${item.name} (${item.phoneNumber})`
            }

            return item.phoneNumber;

          })();

          this.smsClis.push( {
            value: item.id,
            name: name
          })
        })
      })
    },

    loadOutboundCallCliOptions(){
      this.outboundCallClis = []
      this.$http.get("/phone-numbers?outboundCall", {params: { organisation: this.organisationId }}).then(({data}) => {
        data.forEach((item)=> {

          const name = (() => {
            if(item.name){
              return `${item.name} (${item.phoneNumber})`
            }

            return item.phoneNumber;

          })();

          this.outboundCallClis.push( {
            value: item.id,
            name: name
          })
        })
      })
    },


    loadContactGroupOptions(){
      this.contactGroups = []
      this.$http.get("/contact-groups",{params: { organisation: this.organisationId }}).then(({data}) => {
        data.forEach((item)=> {
          this.contactGroups.push( {
            value: item.id,
            name: `${item.name} (${item.contactsCount})`
          })
        })
      })
    },
    loadOrganisationOptions(){
      this.organisations = []
        if(this.isGlobalAdmin) {
          this.$http.get("/organisations").then(({data}) => {
            data.forEach((item) => {
              this.organisations.push({
                value: item.id,
                name: item.name
              })
            })
          })
        }
    },

    loadEmailAddressOptions(){
      this.emailAddresses = []
      this.emailAddresses.push({
        value: '__custom',
        name: 'Set Custom Sender'
      })

      this.$http.get("/email-addresses", {params: { organisation: this.organisationId }}).then(({data}) => {
        data.forEach((item) => {
          this.emailAddresses.push({
            value: item.id,
            name: item.name
          })
        })
      })
    }
  },
  watch:{
    callAudios(value){
      this.$refs.callAudioSelect.setOptions(value)
    },
    templates(value){
      this.$refs.templateSelect.setOptions(value)
    },
    smsClis(value){
      this.$refs.smsCliSelect.setOptions(value)
    },
    outboundCallClis(value){
      this.$refs.outboundCallCliSelect.setOptions(value)
    },
    organisations(value){
      if(this.isGlobalAdmin) {
        this.$refs.organisationSelect.setOptions(value)
      }
    },
    contactGroups(value){
      this.$refs.contactGroupSelect.setOptions(value)
    },
    template(value){
      this.$http.get(`/templates/${value}`).then(({data}) => {
        this.$refs.emailInput.inputValue = data.emailContent
        this.$refs.smsInput.inputValue = data.smsContent
        this.$refs.smsInput.calculateOverlayText(data.smsContent)
      })
    },
    emailAddresses(value){
      this.$refs.emailAddressSelect.setOptions(value)
    },
    organisationId(value){
      this.loadTemplateOptions()
      this.loadCallAudioOptions()
      this.loadSmsCliOptions()
      this.loadOutboundCallCliOptions()
      this.loadContactGroupOptions()
      this.loadEmailAddressOptions()
    }
  }
}
</script>

<style lang="css">
</style>
