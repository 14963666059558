<template lang="pug">
extends ./pug/NavigationAccordion
</template>

<script>
import uuid from 'uuid/v4'

export default {
  name: 'NavigationAccordion',
  props: {
    label: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: true
    }
  },
  beforeCreate() {
    this.$checkboxId = uuid();
  },
  created() {
    this.isOpen = this.open;
  }
}
</script>

<style lang="sass" scoped>
  .accordion-header
    color: #455060
    font-size: 1em
    font-weight: 600
    text-transform: uppercase
    padding: 0.2em 1em
  .menu
    padding: 0
</style>
