/**
 * Validates values against rules loaded at boot
 * todo: Add promise support for AJAX based validation
 */
class Validator {
  constructor(config) {
    this.rules = Object.safeAssign({}, config.rules)

  }

  validate(rules, value) {
    rules = this.parseRuleString(rules);
    return this.handleRules(rules, value);
  }

  parseRuleString(rules) {
    rules = rules.split('|')
      .map(function(rule) {

        let rule_parts = rule.split(':');

        let rule_object = {
          key: rule_parts[0],
          args: []
        };

        if (rule_parts[1]) {
          rule_object.args = rule_parts[1].split(',');
        }

        return rule_object;

      });

    return rules;
  }

  handleRules(rules, value) {
    let response = {
      valid: true,
      errorMessages: []
    }

    rules.forEach(function(rule, index) {

      let rule_handler = this.findRuleHandler(rule.key)

      if (!rule_handler) {
        console.warn(`Attempting to validate against unknown rule '${rule.key}'`)
        return
      }

      if (!rule_handler.validate(value, rule.args)) {
        response.errorMessages.push(this.generateErrorMessage(rule_handler, rule.args))
      }

    }, this)

    return response;
  }

  findRuleHandler(key) {
    for (let object_key in this.rules) {
      if (this.rules[object_key].key === key) {
        return this.rules[object_key]
      }
    }

    return null
  }

  generateErrorMessage(rule_handler, args) {
    let message = rule_handler.errorMessage;

    for (let i in args) {
      message = message.replace(new RegExp(`:arg\\[${i}\\]`, 'g'), args[i])
    }

    return message;

  }

}

export default Validator