Array.prototype.isEmpty = function() {
  return this.length === 0
}

Array.prototype.isNotEmpty = function() {
  return this.length > 0
}

Array.prototype.first = function() {
  if (this.isNotEmpty()) {
    return this[0]
  }

  return null
}