<template lang="pug">
div.tile.tile-centered
  div.tile-icon
    div.call-audio-tile-icon
      i.icon.icon-message.centered
  div.tile-content
    div.tile-title {{name}}
      div.tile-subtitle.text-gray {{createdAt}}
  div.tile-action.delete
    a.delete-tile-icon(@click="promptDelete", href="#")
      i.icon.icon-delete()
  div.tile-action
    a.edit-tile-icon(:href="href")
      i.icon.icon-edit
  div.modal(:class="{active: showDelete}")
    a.modal-overlay(@click="hideDelete")
    div.modal-container
      div.modal-header
        a.btn.btn-clear.btn-modal-close.float-right(@click="hideDelete")
        div.modal-title.h5 Are you sure?
      div.modal-body Call audio '{{name}}' will be permanently deleted.

      div.modal-footer
        button.btn(@click="hideDelete") Cancel
        button.btn.btn-error(@click="submitDelete") Delete
</template>

<script>
export default {
  name: 'CallAudioTile',
  props: {
    href: String,
    name: String,
    createdAt: String,
    deleteHref: String
  },
  data(){
    return{
      showDelete: false
    }
  },
  methods: {
    promptDelete(){
      this.showDelete = true
    },
    hideDelete(){
      this.showDelete = false
    },
    submitDelete(){
      this.$http.post(this.deleteHref).then((response) => {
        if(response.data._redirect == 'reload'){
          window.location.reload();
        }
      }).catch((error)=>{
        console.error(error)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../../scss/variables"

.tile
  // border: 1px solid $primary-color
  box-shadow: 0px 2px 3px rgba(0,0,0,0.2)
  &:not(:last-child)
    margin-bottom: 1rem

  .call-audio-tile-icon
    align-content: space-around
    align-items: center
    justify-content: center
    background: $primary-color
    color: #fff
    display: flex
    display: -ms-flexbox
    font-size: 1.2rem
    height: 2.5rem
    width: 2.5rem

  .edit-tile-icon
    align-content: space-around
    align-items: center
    justify-content: center
    display: flex
    display: -ms-flexbox
    font-size: 1.2rem
    height: 2.5rem
    width: 2.5rem

  .delete-tile-icon
    align-content: space-around
    align-items: center
    justify-content: center
    display: flex
    display: -ms-flexbox
    font-size: 1.2rem
    height: 2.5rem
    width: 2.5rem
    i
      color: $error-color


</style>
