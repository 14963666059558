// Load Vue
import Vue from 'vue/dist/vue'

// Load Validator and bind to Vue
import Validator from './validation-boot'
Vue.prototype.$validator = Validator

import Language from './language-boot'
Vue.prototype.$languageProvider = Language;

Vue.prototype.$lang = function(key) {
  return this.$languageProvider.get(key)
}

const csrf_token = document.head.querySelector('meta[name="csrf"]').content
Vue.prototype.$csrf = csrf_token

import Axios from 'axios'
Vue.prototype.$http = Axios
Vue.prototype.$forms = {}

Vue.prototype.$validationErrors = JSON.parse(document.head.querySelector('meta[name="errors"]').content)

Vue.prototype.$alerts = JSON.parse(document.head.querySelector('meta[name="alerts"]').content)

// Load Vue components
import App from '../vue-components/App'

import InputText from '../vue-components/inputs/InputText'
import InputPassword from '../vue-components/inputs/InputPassword'
import InputTextArea from '../vue-components/inputs/InputTextArea'
import InputCheckbox from '../vue-components/inputs/InputCheckbox'
import InputFileUpload from '../vue-components/inputs/InputFileUpload'
import InputSelect from '../vue-components/inputs/InputSelect'

import FormPanel from '../vue-components/forms/FormPanel'
import FormPage from '../vue-components/forms/FormPage'

import Navigation from '../vue-components/layout/navigation/Navigation'
import NavigationBrand from '../vue-components/layout/navigation/NavigationBrand'
import NavigationAccordion from '../vue-components/layout/navigation/NavigationAccordion'
import NavigationItem from '../vue-components/layout/navigation/NavigationItem'

import Paginator from '../vue-components/layout/navigation/Paginator'

import BablMessage from '../vue-components/layout/alerts/BablMessage'

import UserTile from '../vue-components/layout/users/UserTile'
import TemplateTile from '../vue-components/layout/templates/TemplateTile'
import OrganisationTile from '../vue-components/layout/organisations/OrganisationTile'
import ContactGroupTile from '../vue-components/layout/contact-groups/ContactGroupTile'
import ContactTile from '../vue-components/layout/contacts/ContactTile'
import AlertTile from '../vue-components/layout/alerts/AlertTile'
import CallAudioTile from '../vue-components/layout/call-audio/CallAudioTile'
import PhoneNumberTile from '../vue-components/layout/phone-numbers/PhoneNumberTile'
import EmailAddressTile from '../vue-components/layout/email-addresses/EmailAddressTile.vue'

import AlertTemplate from '../vue-components/form-components/AlertTemplate'
import AlertData from '../vue-components/layout/alerts/AlertData'

// Boot Vue
const app = new Vue({
  el: '#app',
  components: {
    App,

    InputText,
    InputPassword,
    InputTextArea,
    InputCheckbox,
    InputFileUpload,
    InputSelect,

    FormPanel,
    FormPage,

    Navigation,
    NavigationBrand,
    NavigationAccordion,
    NavigationItem,

    Paginator,

    BablMessage,

    UserTile,
    TemplateTile,
    OrganisationTile,

    ContactGroupTile,
    ContactTile,
    AlertTile,
    CallAudioTile,
    PhoneNumberTile,
    EmailAddressTile,

    AlertTemplate,
    AlertData
  },
  mounted() {},
  created() {}
});

window.app = app