Object.defineProperty(Object.prototype, 'defineInnumerable', {
  value: (name, func) => {
    Object.defineProperty(Object.prototype, name, {
      value: func,
      enumerable: false
    })
  },
  enumerable: false
})

// Object.defineInnumerable('forEach', function(func, context) {
//   let value
//   context = context || this //apply the function to 'this' by default
//   for (let key in this) {
//     if (this.hasOwnProperty(key)) { //to rule out inherited properties
//       value = this[key]
//       func.call(context, key, value)
//     }
//   }
// })

Object.defineInnumerable('safeAssign', function(target, ...sources) {
  if (target !== Object(target)) {
    target = {}
  }

  return Object.assign(target, ...sources);

})

Object.defineInnumerable('findFirst', function(key, value) {
  for (let property in this) {
    if (this.hasOwnProperty(property) && this[property].hasOwnProperty(key)) {
      if (this[property][key] == value) {
        return this[property]
      }
    }
  }

  return null
})

Object.defineInnumerable('isEmpty', function() {
  for (let prop in this) {
    if (this.hasOwnProperty(prop)) {
      return false
    }
  }
  return true;
})

Object.defineInnumerable('isNotEmpty', function() {
  return !this.isEmpty()
})