<template lang="pug">
include pug/InputBase.pug
</template>

<script>
import uuid from 'uuid/v4'


export default {
  name: "InputBase",
  props: {
    name: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    rules: {
      type: String,
      required: false
    },
    form: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      type: "text",
      inputValue: this.value,
      errors: [],
      formComponent: null
    }
  },

  watch: {
    inputValue(value) {
      this.$emit('input', value)
    },
    validationValue(value) {
      this.clearErrors()
    }
  },

  computed: {
    validationValue() {
      return this.inputValue
    },
    hasErrors() {
      return this.errors.isNotEmpty()
    }
  },

  mounted() {
    if (this.form && this.$forms[this.form]) {
      this.formComponent = this.$forms[this.form];
      this.formComponent.bindInput(this)
    }


    if (this.value && this.rules) {
      this.validate()
    }


    this.loadErrors()


  },

  beforeCreate() {
    this.$id = uuid()
  },
  methods: {
    submitForm(){
      if(this.formComponent){
        this.formComponent.submit()
      }
    },

    validate() {
      if (this.rules) {
        this.errors = this.$validator.validate(this.rules, this.validationValue).errorMessages
      }
    },
    loadErrors() {

      if (this.$root.$validationErrors) {
        this.$root.$validationErrors.forEach((error) => {
          if (error.field == this.name) {
            this.errors.push(error.message)
          }
        })
      }
    },
    clearErrors() {
      this.errors = []
    }
  }

}
</script>

<style scoped>

</style>
