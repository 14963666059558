<template lang="pug">
extends ./pug/InputTextArea.pug
</template>

<script>
import InputBase from './InputBase.vue'

export default {
  extends: InputBase,
  name: "InputTextArea",
  props: {
    overlayHighlighter: {
      type: String,
      default: null
    }
  },
  data(){
    return {
      overlayText: ""
    }
  },
  watch: {
    inputValue(value){
      this.resize()

      this.overlayText = this.calculateOverlayText(value)
    }
  },
  methods:{
    resize(){
      let el = this.$refs.textarea
      el.style.cssText = 'height:auto; padding:0';
      el.style.cssText = 'height:' + (parseInt(el.scrollHeight) + 16) + 'px';
    },
    calculateOverlayText(value){
      if(!value){
        value = ''
      }
      switch(this.overlayHighlighter){
        case 'gsm': return this.overlayGsm(value)
        default: return value
      }
    },
    overlayGsm(value){
      return value.replace(/([^A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,-./:;<>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*)/g, "<span class=\"highlight\">$1</span>")
    },

  }
}
</script>

<style lang="sass">
@import "../../../scss/variables"

.form-input-hint
  margin-bottom: 0.2rem
textarea
  resize: vertical
  min-height: 10em
  &::-webkit-scrollbar
    display: none
.text-container
  position: relative
  .overlay
    pointer-events: none
    margin: 0
    position: absolute
    top: 0
    left: 0
    padding: 0.5em 1em
    border: 1px solid rgba(0,0,0,0)
    font: 300 1em "Muli", sans-serif
    color:  rgba(0,0,0,0)
    .highlight
      background: lighten($error-color, 20%)
      color: $ngs-grey

</style>
