<template lang="pug">
ul.pagination
	li.page-item(:class="{disabled: current == 1 }",)
		a(:href="pageUrl(current - 1)") Previous

	li.page-item(v-if="showFirst")
		a(:href="pageUrl(first)") {{first}}

	li.page-item(v-if="showFirst")
		span ...

	li.page-item(v-for="link in links", :class="{active: link.active}")
		a(:href="pageUrl(link.page)") {{link.page}}

	li.page-item(v-if="showLast")
		span ...

	li.page-item(v-if="showLast")
		a(:href="pageUrl(last)") {{last}}

	li.page-item(:class="{disabled: current == last }",)
		a(:href="pageUrl(current + 1)") Next
</template>

<script>
export default {
  name: "Paginator",
  props: {
    current: {
      type: Number,
      required: true,
    },
    last: {
      type: Number,
      required: true
    }
  },
  methods: {
    pageUrl(page) {
      return `?page=${page}`
    }
  },
  data() {
    return {
      links: [],
      showFirst: true,
      showLast: true,
      first: 1
    }

  },

  created() {
    for (let i = -1; i <= 1; i++) {
      let page = parseInt(this.current) + i

      if (page == this.first) {
        this.showFirst = false
      }

      if (page == this.last) {
        this.showLast = false
      }

      if (page >= this.first && page <= this.last) {
        this.links.push({
          page: page,
          active: page == this.current
        })
      }
    }
  }
}
</script>

<style lang="css">
</style>
