<template lang="pug">
div.tile.tile-centered
  div.tile-icon
    div.inbound-number-tile-icon
      i.icon.icon-message.centered
  div.tile-content
    div.tile-title
      | {{ title }} 
      span.type-option(v-if="sms") SMS
      span.type-option(v-if="inboundCalls") Inbound Calls
      span.type-option(v-if="outboundCalls") Outbound Calls
    div.tile-subtitle.text-gray {{audioFile || 'No audio file set'}}
  div.tile-action.delete(v-if="deleteHref")
    a.delete-tile-icon(@click="promptDelete", href="#")
      i.icon.icon-delete()
  div.tile-action
    a.edit-tile-icon(:href="href")
      i.icon.icon-edit

  div.modal(:class="{active: showDelete}")
    a.modal-overlay(@click="hideDelete")
    div.modal-container
      div.modal-header
        a.btn.btn-clear.btn-modal-close.float-right(@click="hideDelete")
        div.modal-title.h5 Are you sure?
      div.modal-body Phone Number '{{title}}' will be permanently deleted.

      div.modal-footer
        button.btn(@click="hideDelete") Cancel
        button.btn.btn-error(@click="submitDelete") Delete
</template>

<script>
export default {
  name: 'PhoneNumberTile',
  props: {
    href: String,
    phoneNumber: String,
    name: String,
    audioFile: String,
    sms: Boolean,
    inboundCalls: Boolean,
    outboundCalls: Boolean,
    deleteHref: String,

  },
  data(){
    return {
      showDelete: false
    }
  },
  computed: {
    title(){
      if(this.name){
        return `${this.name} (${this.phoneNumber})`
      }

      return `${this.phoneNumber}`
    }
  },
  methods: {
    promptDelete(){
      this.showDelete = true
    },
    hideDelete(){
      this.showDelete = false
    },
    submitDelete(){
      this.$http.post(this.deleteHref).then((response) => {
        if(response.data._redirect == 'reload'){
          window.location.reload();
        }
      }).catch((error)=>{
        console.error(error)
      })
    }
  }


}
</script>

<style lang="sass" scoped>
@import "../../../../scss/variables"

.tile
  // border: 1px solid $primary-color
  box-shadow: 0px 2px 3px rgba(0,0,0,0.2)
  &:not(:last-child)
    margin-bottom: 1rem

  .inbound-number-tile-icon
    align-content: space-around
    align-items: center
    justify-content: center
    background: $primary-color
    color: #fff
    display: flex
    display: -ms-flexbox
    font-size: 1.2rem
    height: 2.5rem
    width: 2.5rem

  .edit-tile-icon
    align-content: space-around
    align-items: center
    justify-content: center
    display: flex
    display: -ms-flexbox
    font-size: 1.2rem
    height: 2.5rem
    width: 2.5rem

  .delete-tile-icon
    align-content: space-around
    align-items: center
    justify-content: center
    display: flex
    display: -ms-flexbox
    font-size: 1.2rem
    height: 2.5rem
    width: 2.5rem
    i
      color: $error-color

  .tile-title
    display: flex
    align-content: center
    align-items: center
  

  .type-option
    font-size: 0.5rem
    background: $primary-color
    color: #fff
    text-transform: uppercase
    font-weight: bold
    margin-left: 0.4rem
    border-radius: 4px
    padding: 0 0.2rem
    display: inline-block
    height: 1.5em
    line-height: 1.5
</style>
