<template lang="pug">
div.app.off-canvas.off-canvas-sidebar-show(v-cloak)
	slot
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  }
}
</script>

<style lang="sass">
@import "../../scss/main"

@media only screen and (max-width: 960px)
  .app .off-canvas-content
    padding-left: 0.4rem


.app
  min-height: 100vh
  .off-canvas-content
    height: auto
    position: relative
    /*padding-top: 2rem*/

[v-cloak]
  position: relative
  *
    display: none
  &::before
    height: 1.6rem
    width: 1.6rem
    margin-left: -.8rem
    margin-top: -.8rem
    animation: loading .5s infinite linear
    border: .1rem solid #5755d9
    border-radius: 50%
    border-right-color: transparent
    border-top-color: transparent
    content: ""
    display: block
    left: 50%
    position: absolute
    top: 50%
    z-index: 1

</style>
