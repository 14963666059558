<template lang="pug">
extends pug/FormPage
</template>

<script>
import FormBase from './FormBase'

export default {
  extends: FormBase,
  name: "FormPage"
}
</script>

<style lang="sass">
.form-footer
  text-align: right
  margin-top: 1rem

</style>
