module.exports = {
  key: 'en',
  volcabulary: {
    test: "This is a test!",
    validation: {
      errors: {
        email: "Must be a valid email address",
        maxLength: "Must be no longer than :arg[0] characters",
        required: "Field is required",
        unique: {
          email: "Email address is already in use"
        },
        same: {
          passwordVerify: "Does not match password"
        },
        phone: "Must be a valid phone number",
        gsm: "Text contains invalid characters for SMS"

      }
    },
    headers: {
      login: 'Login'
    },
    form: {
      labels: {
        email: 'Email',
        password: 'Password'
      },
      buttons: {
        login: 'Login',
        createUser: "Create User",
        updateUser: "Update User",

        createTemplate: "Create Template",
        updateTemplate: "Update Template",
        createEmailAddress: "Create Email Address",
        updateEmailAddress: "Update Email Address",
        createContactGroup: "Create Contact Group",
        updateContactGroup: "Update Contact Group",
        createContact: "Create Contact",
        updateContact: "Update Contact",
        createAlert: "Send Alert",
        createCallAudio: "Create Call Audio",
        updateCallAudio: "Update Call Audio",
        createPhoneNumber: 'Create Phone Number',
        updatePhoneNumber: 'Update Phone Number',
        createOrganisation: 'Create Organisation',
        updateOrganisation: 'Update Organisation',

        updateProfile: 'Update Profile'
      },
      placeholders: {
        password: "Leave blank to keep same password"
      }
    }
  },
  terms: {}
}