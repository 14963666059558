<template lang="pug">
extends ./pug/NavigationBrand
</template>

<script>
export default {
  name: 'NavigationBrand',
  props: {
    image: {
      type: String,
      required: true
    },
    href: {
      type: String,
      default: '/'
    }
  }
}
</script>

<style lang="sass" scoped>
.logo
    display: block
    img
        max-width: 100%
    

</style>
