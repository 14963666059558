<template lang="pug">
extends pug/FormBase
</template>

<script>
import BablMessage from '../layout/alerts/BablMessage'

export default {
  name: 'FormBase',
  components: {
    BablMessage
  },

  props: {
    header: {
      type: String,
      required: false
    },
    submitLabel: {
      type: String,
      default: 'Submit'
    },
    action: {
      type: String,
      required: true
    },
    method: {
      type: String,
      default: 'post'
    },
    name: {
      type: String,
      required: false
    },
    ajax: {
      type: Boolean
    }
  },

  data() {
    return {
      inputs: [],
      alerts: [],
      sending: false
    }
  },

  methods: {
    validate() {
      let valid = true

      this.inputs.forEach((input) => {
        input.validate()
        if (input.hasErrors) {
          valid = false
        }
      })

      return valid
    },


    submit() {

      if (this.validate()) {

        if (this.ajax && !this.sending) {
          let data = new FormData(this.$refs.form)
          this.sending = true;
          this.$http.post(`${this.action}?_method=${this.method}`, data).then((response) => {
            if (response.data._redirect) {
              window.location.href = response.data._redirect
            }
          }).catch((error) => {

            if (Array.isArray(error.response.data)) {
              this.$root.$validationErrors = error.response.data
            } else {
              this.$root.$validationErrors = []
            }

            if (error.response.data.hasOwnProperty('_alerts')) {
              this.alerts = error.response.data._alerts
            }



            if(this.alerts.isEmpty() && error.response.status == 500){
              this.alerts = [{
                type: 'error',
                message: 'An error has occurred, please try again'
              }]
            }


            this.inputs.forEach((input) => {
              input.loadErrors()
            })
          }).then(()=>{
            this.sending = false
          });
          return;
        }

        this.$refs.form.submit()

      }



      // this.$refs.form.submit()
    },

    bindInput(input) {
      this.inputs.push(input)
    }
  },


  created() {
    if (this.name) {
      this.$forms[this.name] = this
    }

    if(this.$alerts){
      this.alerts = this.$alerts
    }
  }

}
</script>

<style lang="sass">
</style>
