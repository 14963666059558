<template lang="pug">
div.toast(:class="`toast-${type}`", v-show="visible")
	button.btn.btn-clear.btn-toast.float-right(@click="visible=false", type="submit")
	| {{message}}
</template>

<script>
export default {
  name: "BablMessage",
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: true
    }
  },
}
</script>

<style lang="sass" scoped>
</style>
