<template lang="pug">
extends ./pug/InputFileUpload.pug
</template>

<script>
import InputBase from './InputBase.vue'

export default {
  extends: InputBase,
  name: "InputFileUpload",
  props: {
    accept: {
      type: String,
      default: '*'
    },
    multiple: {
      type: Boolean
    }
  },
  data(){
    return {
      type: 'file'
    }
  },
  computed: {
    inputName(value){
        return this.inputValue ? this.name : ''
    },
  },
  watch: {
    errors(value){
      if(value.isNotEmpty()){
        this.$refs.input.type = ''
        this.$refs.input.type = 'file'
      }
    }
  },
  methods:{
    setInput(event){
      let files = Array.from(event.target.files)
      this.inputValue = files.first()
    }
  }
}
</script>

<style lang="sass">
.form-input-hint
  margin-bottom: 0.2rem
textarea
  resize: vertical
  min-height: 10em
  &::-webkit-scrollbar
    display: none

</style>
