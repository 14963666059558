<template lang="pug">
extends ./pug/InputBase.pug
</template>

<script>
import InputBase from './InputBase.vue'

export default {
  extends: InputBase,
  name: "InputPassword",
  data() {
    return {
      type: "password"
    }
  }
}
</script>

<style scoped>

</style>
