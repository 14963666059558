<template lang="pug">
div.alert-data
  div.totals(v-if="alertData && alertData.counts")
    div.responded Responded {{alertData.counts.responded.length}}
    div.responded Not Responded {{alertData.counts.notResponded.length}}
  div.alert-data-sms.panel(v-if="alertData && alertData.counts && alertData.counts.sms")
    div.spacer.g-1-1
    div.header.g-1-2 Total
    div.header.g-1-3 Processing
    div.header.g-1-4 Sent
    div.header.g-1-5 Failed
    div.header.g-1-6 Invalid
    div.header.g-1-7 Undelivered
    div.header.g-1-8 Delivered
    div.header.g-1-9 Replied To
    div.title.g-2-1 SMS
    div.count.g-2-2 {{alertData.counts.sms.total}}
    div.count.g-2-3 {{alertData.counts.sms.processing}}
    div.count.g-2-4 {{alertData.counts.sms.sent}}
    div.count.g-2-5 {{alertData.counts.sms.fail}}
    div.count.g-2-6 {{alertData.counts.sms.invalid}}
    div.count.g-2-7 {{alertData.counts.sms.undelivered}}
    div.count.g-2-8 {{alertData.counts.sms.delivered}}
    div.count.g-2-9 {{alertData.counts.sms.replied}}
  div.alert-data-email.panel(v-if="alertData && alertData.counts && alertData.counts.email")
    div.spacer.g-1-1
    div.header.g-1-2 Total
    div.header.g-1-3 Processing
    div.header.g-1-4 Sent
    div.header.g-1-5 Failed
    div.header.g-1-6 Replied To
    div.spacer.g-1-7
    div.spacer.g-1-8
    div.spacer.g-1-9
    div.title.g-2-1 Emails
    div.count.g-2-2 {{alertData.counts.email.total}}
    div.count.g-2-3 {{alertData.counts.email.processing}}
    div.count.g-2-4 {{alertData.counts.email.sent}}
    div.count.g-2-5 {{alertData.counts.email.fail}}
    div.count.g-2-6 {{alertData.counts.email.replied}}


  div.alert-data-call.panel(v-if="alertData && alertData.counts && alertData.counts.call")
    div.spacer.g-1-1
    div.header.g-1-2 Total
    div.header.g-1-3 Processing
    div.header.g-1-4 Initialised
    div.header.g-1-5 Call Made
    div.header.g-1-6 Ringing
    div.header.g-1-7 Connected
    div.header.g-1-8 Played
    div.header.g-1-9 Acknowledged
    div.title.g-2-1 Calls
    div.count.g-2-2 {{alertData.counts.call.total}}
    div.count.g-2-3 {{alertData.counts.call.processing}}
    div.count.g-2-4 {{alertData.counts.call.init}}
    div.count.g-2-5 {{alertData.counts.call.make}}
    div.count.g-2-6 {{alertData.counts.call.ring}}
    div.count.g-2-7 {{alertData.counts.call.connect}}
    div.count.g-2-8 {{alertData.counts.call.played}}
    div.count.g-2-9 {{alertData.counts.call.ack}}
  div.alert-data-total.panel(v-if="alertData && alertData.counts")
    div.title Total Recipients
    div.count {{totalRecipients}}

  div.alert-data-total.panel(v-if="alertData && alertData.counts")
    div.title Total Replies
    div.count {{totalReplies}}

  div.alert-data-total.panel(v-if="alertData && alertData.owner && alertData.owner.name")
    div.title Alert Sender
    div.count {{alertData.owner.name}}
</template>

<script>
export default {
  name: 'AlertData',
  props: {
    dataHref: String,
  },
  data(){
    return {
      alertData: null
    }
  },
  computed: {
    totalRecipients(){
      if(this.alertData && this.alertData.counts){
         return this.alertData.counts.call.total +  this.alertData.counts.sms.total +  this.alertData.counts.email.total
      }

      return 0
    },
    totalReplies(){
      if(this.alertData && this.alertData.counts){
         return this.alertData.counts.call.ack +  this.alertData.counts.sms.replied +  this.alertData.counts.email.replied
      }

      return 0
    }
  },
  methods: {
    loadData(){
      this.$http.get(this.dataHref).then(({data}) => {
        this.alertData = data
        setTimeout(() => {
          this.loadData()
        }, 5000)
      })
    }

  },
  mounted(){
    this.loadData()
  }
}
</script>

<style lang="sass" scoped>
@import "../../../../scss/variables"



.alert-data-total
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr
  .spacer
    background: none
.alert-data-sms, .alert-data-call, .alert-data-email
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
.alert-data
  .header
    font-size: 0.9em
  .header, .title
    font-weight: bold
  .header, .title, .count
    padding: 0.3em
  .header, .spacer
    background: #dadee4
  .count
    background: white

.panel
  margin-bottom: 1em

@for $y from 1 through 2
  @for $x from 1 through 10
    .g-#{$y}-#{$x}
      grid-column: $x
      grid-row: $y

@media(max-width: 1024px)
  .alert-data-total, .alert-data-sms, .alert-data-call, .alert-data-email
    grid-template-columns: 1fr 1fr
  @for $x from 1 through 2
    @for $y from 1 through 10
      .g-#{$x}-#{$y}
        grid-column: $x
        grid-row: $y
</style>
