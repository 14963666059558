<template lang="pug">
extends ./pug/Navigation.pug
</template>

<script>
export default {
  name: 'Navigation'
}
</script>

<style lang="sass" scoped >

@import "../../../../scss/variables"
.babl-navigation
    width: 12em
    background: $ngs-grey
    a
      color: #ffffff

    > a
      padding: 0.5em
      font-size: 1.2em
      text-transform: uppercase
      display: block
      transition: all 0.5s ease 0s

      &:hover
        background-color: $ngs-red
        text-decoration: none

@media(min-width: 1121px)
  .babl-navigation






</style>
