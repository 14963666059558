<template lang="pug">
extends ./pug/InputBase.pug
</template>

<script>
import InputBase from './InputBase.vue'

export default {
  extends: InputBase,
  name: "InputText",
}
</script>

<style lang="sass">
.form-input-hint
  margin-bottom: 0.2rem
</style>
