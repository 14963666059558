import Language from 'babl-language'

import EN from '../../../../languages/EN'
import EN_GB from '../../../../languages/EN_GB'

const language = new Language({
  packs: {
    EN,
    EN_GB
  },
  defaultLanguage: 'en-gb'
});

export default language;