<template lang="pug">
extends pug/FormPanel
</template>

<script>
import FormBase from './FormBase'

export default {
  extends: FormBase,
  name: "FormPanel"
}
</script>

<style lang="sass">
</style>
