<template lang="pug">
extends ./pug/InputCheckbox.pug
</template>

<script>
import InputBase from './InputBase.vue'

export default {
  extends: InputBase,
  name: "InputCheckbox",
  props: {
    checked: Boolean
  },
  data(){
    return {
      checkbox: this.checked,
      hiddenValue: this.checked ? 1 : 0
    }
  },
  watch: {
    checkbox(value){
      this.hiddenValue = this.checkbox ? 1 : 0
    }
  },
  computed: {
    boolValue(){
      return this.checkbox ? true : false
    }
  }
}
</script>

<style lang="sass">
.form-input-hint
  margin-bottom: 0.2rem
</style>
