<template lang="pug">
extends ./pug/NavigationItem
</template>

<script>
export default {
  name: 'NavigationItem',
  props: {
    href: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="sass" scoped>
  .menu-item
    font-size: 1em
    a
      padding-left: 2rem
      padding-bottom: 0.4rem

</style>
