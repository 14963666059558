import Validator from '~/validation/Validator'
import Email from '~/validation/rules/Email'
import MaxLength from '~/validation/rules/MaxLength'
import Required from '~/validation/rules/Required'

const validator = new Validator({
  rules: {
    Email,
    MaxLength,
    Required
  }
});

export default validator;